import { Logger } from '@compass/logging';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../security/authentication.service';
import { Router } from '@angular/router';

/**
 * States the assessment can be in.
 */
export enum AssessmentState {
  Ready,
  InProgress,
  Resume,
  Expired,
  Support,
  Complete,
}

/**
 * Represents the possible state transitions for an assessment.
 */
const assessmentStateTransitions: {
  from: AssessmentState;
  to: AssessmentState[];
}[] = [
  {
    from: AssessmentState.Ready,
    to: [
      AssessmentState.InProgress,
      AssessmentState.Resume,
      AssessmentState.Complete,
      AssessmentState.Expired,
    ],
  },
  {
    from: AssessmentState.InProgress,
    to: [
      AssessmentState.Expired,
      AssessmentState.Resume,
      AssessmentState.Support,
      AssessmentState.Complete,
    ],
  },
  {
    from: AssessmentState.Support,
    to: [
      AssessmentState.InProgress,
      AssessmentState.Resume,
      AssessmentState.Expired,
    ],
  },
  {
    from: AssessmentState.Expired,
    to: [
      AssessmentState.InProgress,
      AssessmentState.Support,
      AssessmentState.Complete,
    ],
  },
  {
    from: AssessmentState.Resume,
    to: [AssessmentState.InProgress, AssessmentState.Support],
  },
];

/**
 * Service that manages the state of an assessment.
 */
@Injectable({
  providedIn: 'root',
})
export class AssessmentStateService {
  private _pendingStateChange: boolean = false;
  private _lastState = AssessmentState.Ready;
  private _currentState = AssessmentState.Ready;

  /**
   * Retrieves the current state of the assessment.
   *
   * @returns {AssessmentState} The current state of the assessment.
   */
  get currentState(): AssessmentState {
    return this._currentState;
  }

  /**
   * Retrieves the pending state change status.
   * State is pending if new state was requested but
   * the state has not changed yet.
   *
   * @returns {boolean} The pending state change status.
   */
  get pendingStateChange(): boolean {
    return this._pendingStateChange;
  }

  constructor(
    private readonly _logger: Logger,
    private readonly _authService: AuthenticationService,
    private readonly _router: Router,
  ) {}

  /**
   * Sets the state of the assessment.
   *
   * @param {AssessmentState} state - The new state to set.
   * @returns {void}
   */
  setState(state: AssessmentState): void {
    if (state === this.currentState) return;

    if (!this.isValidStateTransition(state)) {
      this.logInvalidStateTransition(this.currentState, state);
      return;
    }

    this._pendingStateChange = true;

    const stateBeforeNavigation = this._currentState;
    this._currentState = state;

    this.tryNavigateForState(this._currentState)
      .then(success => {
        if (success) {
          this._logger.debug(
            `Assessment state changed from '${AssessmentState[stateBeforeNavigation]}' to '${AssessmentState[state]}'.`,
          );

          this._lastState = stateBeforeNavigation;
        } else {
          this._logger.debug(
            `Navigation was blocked to page for state [${AssessmentState[state]}]. The state remains [${AssessmentState[stateBeforeNavigation]}].`,
          );

          this._currentState = stateBeforeNavigation;
        }
      })
      .finally(() => {
        this._pendingStateChange = false;
      });
  }

  /**
   * Reverts the state of the component to the previous state.
   *
   * @returns {void}
   */
  goBack(): void {
    this.setState(this._lastState);
  }

  /**
   * Resets the state of the assessment to {@link AssessmentState.Ready}.
   * @return {void}
   */
  reset(): void {
    this._currentState = AssessmentState.Ready;
  }

  private tryNavigateForState(state: AssessmentState): Promise<boolean> {
    let url = this.buildUrl();

    switch (state) {
      case AssessmentState.Ready:
      case AssessmentState.InProgress:
        break;
      case AssessmentState.Resume:
        url = this.buildUrl('resume');
        break;
      case AssessmentState.Expired:
        url = this.buildUrl('expired');
        break;
      case AssessmentState.Support:
        url = this.buildUrl('support');
        break;
      case AssessmentState.Complete:
        url = this.buildUrl('complete');
        break;
    }

    return this._router.navigateByUrl(url, {
      onSameUrlNavigation: 'reload',
    });
  }

  private buildUrl(path?: string): string {
    const startPath = '/' + this._authService.startCode;

    return path ? `${startPath}/${path}` : startPath;
  }

  private isValidStateTransition(to: AssessmentState): boolean {
    const index = assessmentStateTransitions.findIndex(
      t => t.from === this.currentState && t.to.includes(to),
    );

    return index >= 0;
  }

  private logInvalidStateTransition(
    from: AssessmentState,
    to: AssessmentState,
  ): void {
    this._logger.error(
      `Cannot go from '${AssessmentState[from]}' to '${AssessmentState[to]}' because it is not a valid state transition.`,
    );
  }
}
