<div class="d-inline-block" [ngbTooltip]="tooltipText">
  @if (showButton) {
    <button
      class="btn btn-success py-3 px-4"
      [disabled]="buttonDisabled"
      [cpParHotkey]="KeyboardKey.ArrowRight"
      (click)="onClick()">
      @if (isBusy()) {
        <div class="spinner-border spinner-border-sm"></div>
      } @else {
        {{ presentation.current.presentationItem.navigationNextLabel }}
      }
    </button>
  }
</div>
