import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  CommonModule,
  formatNumber,
  getLocaleNumberSymbol,
  NumberSymbol,
} from '@angular/common';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Mexp from 'math-expression-evaluator';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { HotkeyService } from '../../services/ui/hotkey.service';
import { CalculatorService } from '../../services/ui/calculator.service';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import {
  DoNotFocusDirective,
  FaIconComponent,
  SwipeDirective,
} from '@compass/ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DeviceInfoService } from '@compass/environment';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

export enum CalculatorAction {
  Number,
  Operation,
  Percent,
}

@Component({
  selector: 'cp-par-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HotkeyDirective,
    DoNotFocusDirective,
    SwipeDirective,
    FaIconComponent,
    DragDropModule,
    NgbTooltip,
  ],
})
export class CalculatorComponent implements OnInit, OnDestroy {
  private readonly MAX_DIGITS = 9;
  private readonly _locale: string = 'en-US';
  private readonly _epsilon = 1e-10;
  private _typeText: string = '';
  private _formula: string[] = [];
  private _lastAction: CalculatorAction = CalculatorAction.Number;
  private _result?: number;
  private _hotkeySubscriptionId?: number;
  private _canDrag: boolean = true;

  readonly _decimalSeparator = getLocaleNumberSymbol(
    this._locale,
    NumberSymbol.CurrencyDecimal,
  );
  readonly KeyboardKey = KeyboardKey;

  @Input()
  set canDrag(value: boolean) {
    if (!value && this._calculatorWrapper) this.resetPosition();

    this._canDrag = value;
  }

  get canDrag(): boolean {
    return this._canDrag;
  }

  @Input()
  deviceType: string = '';

  @ViewChild('calculatorWrapper')
  _calculatorWrapper!: ElementRef;

  @HostBinding('class.d-none')
  get isVisible(): boolean {
    return !this._calculatorService.visible;
  }

  @HostBinding('class.calculator-tablet') tabletMode: boolean = false;

  constructor(
    private readonly _hotkeyService: HotkeyService,
    public readonly _calculatorService: CalculatorService,
    private readonly _renderer: Renderer2,
    protected readonly deviceInfo: DeviceInfoService,
  ) {}

  ngOnInit(): void {
    this._hotkeySubscriptionId = this._hotkeyService.add(
      KeyboardKey.Backspace,
      this.onBackspace.bind(this),
    );

    this._calculatorService.populateTypeText.subscribe(newResult => {
      this.setTypeText(newResult);
      if (!this._calculatorService.visible) {
        this._calculatorService.show();
      }
    });
  }

  ngOnDestroy(): void {
    this._calculatorService.hide();

    if (this._hotkeySubscriptionId) {
      this._hotkeyService.remove(this._hotkeySubscriptionId);
    }
  }

  get displayText(): string {
    let output: number | string = 0;

    if (this._result) {
      output = this._result;
    } else if (this._typeText) {
      output = this._typeText;
    }

    return this._formatOutputNumber(output);
  }

  get formula(): string[] {
    return this._formula;
  }

  get hasResult(): boolean {
    return !!this._result;
  }

  get hasError(): boolean {
    // If there is no result then there is no error
    if (!this._result) return false;
    // If the result has more than 9 digits in its integral part then it's an error
    if (
      this.countDigits(this._result.toString().split('.')[0]) > this.MAX_DIGITS
    ) {
      return true;
    }

    // Otherwise, check the result for infinity and NaN
    return Number.isNaN(this._result) || !Number.isFinite(this._result);
  }

  // inserts from outside component insertion.
  setTypeText(number: string): void {
    if (number.split('')[0] === '.') number = '0' + number;

    this._typeText = '';
    this._onNumber(number);
  }

  resetPosition(): void {
    this._renderer.removeAttribute(
      this._calculatorWrapper.nativeElement,
      'style',
    );
  }

  clear(): void {
    this.clearTypeText();
    this._lastAction = CalculatorAction.Number;
    this._result = undefined;
    this._formula = [];
  }

  _onBackspace(): void {
    if (this._typeText.length < 2 && !this._result) {
      this.clearTypeText();
      return;
    }

    if (this._result) {
      const result = this._result;
      this.clear();
      this._typeText = result.toString();
    }

    this._typeText = this._typeText.slice(0, -1);
  }

  _onNumber(value: string): void {
    if (
      this._lastAction === CalculatorAction.Operation ||
      this._lastAction === CalculatorAction.Percent
    ) {
      this.clearTypeText();
    }
    if (this.countDigits(this._typeText) >= this.MAX_DIGITS) return;
    if (this.hasResult) {
      this.clear();
    }

    // If value is zero and text consists of zeros only...
    if (value === '0' && this._typeText === '0') return;

    this._typeText += value;
    this._lastAction = CalculatorAction.Number;
  }

  _onDecimal(): void {
    if (this.hasError) return;
    if (this.hasResult) this.clear();
    if (this._lastAction === CalculatorAction.Operation) this.clearTypeText();
    // Do not add decimal if present
    if (this._typeText.indexOf(this._decimalSeparator) > -1) return;
    // Add leading 0 if it is empty
    if (!this._typeText) {
      this._typeText += '0';
    }

    this._typeText += this._decimalSeparator;
    this._lastAction = CalculatorAction.Number;
  }

  _onOperation(operation: string): void {
    if ((!this._typeText && !this.hasResult) || this.hasError) return;
    if (this.hasResult) this.newFormula();

    // If we just did operation then pop the last operation
    if (this._lastAction === CalculatorAction.Operation) {
      this._formula.pop();
    } else {
      this.storeToFormula(this._typeText);
    }

    this.storeToFormula(operation);
    this._lastAction = CalculatorAction.Operation;
  }

  _onEqual(): void {
    if (!this._typeText || this.hasError) return;

    this.storeToFormula(this._typeText);
    // Get the formula text for the calculator
    const formula = this._formula.join('');

    const mexp = new Mexp();
    this._result = mexp.eval(formula);
    this.clearTypeText();
  }

  _onPercentage(): void {
    if ((!this._typeText && !this.hasResult) || this.hasError) return;
    if (this.hasResult) this.newFormula();

    this._typeText = this.removeTrailingZeros(
      (parseFloat(this._typeText.substring(0, this.MAX_DIGITS)) / 100).toFixed(
        10,
      ),
    );

    this._lastAction = CalculatorAction.Percent;
  }

  _formatOutputNumber(typedNumber: string | number): string {
    return this.formatLocaleNumber(typedNumber.toString());
  }

  private onBackspace(): void {
    if (!this._typeText) return;
    if (this._typeText.length === 1) {
      this._typeText = '0';
    } else {
      this._typeText = this._typeText.substring(0, this._typeText.length - 1);
    }
  }

  private removeTrailingZeros(value: string): string {
    if (!value.includes('.')) return value;

    return value.replace(/((?=(0{2,9}))\2)+$/, '');
  }

  private storeToFormula(text: string): void {
    if (!text) return;
    if (text.endsWith('.')) {
      text = text.substring(0, text.length - 1);
    }

    this._formula.push(text);
  }

  private clearTypeText(): void {
    this._typeText = '';
  }

  private newFormula(): void {
    const newTypeText = this._result?.toString();
    this.clear();
    this._typeText = newTypeText ?? '';
  }

  private formatLocaleNumber(x: string): string {
    const numberParts = x.split(this._decimalSeparator);
    // If there is no decimal part
    if (numberParts.length === 1) {
      return formatNumber(parseFloat(x), this._locale);
    }

    return this.formatDecimalNumber(numberParts[0], numberParts[1]);
  }

  private formatDecimalNumber(
    integralPart: string,
    decimalPart: string,
  ): string {
    const intFormatted =
      formatNumber(parseFloat(integralPart), this._locale) +
      this._decimalSeparator;
    // If there is no decimal part then return the formatted integral part plus the decimal separator
    if (!decimalPart) return intFormatted;

    const maximumFractionDigits = this.MAX_DIGITS - integralPart.length;
    const minimumFractionDigits =
      decimalPart.length > maximumFractionDigits
        ? maximumFractionDigits
        : decimalPart.length;

    let decimalFormatted =
      this._lastAction === CalculatorAction.Percent
        ? `0.${decimalPart.substring(0, minimumFractionDigits)}`
        : formatNumber(
            parseFloat(`0.${decimalPart}`),
            this._locale,
            `0.${minimumFractionDigits}-${maximumFractionDigits}`,
          );

    if (this._result && +decimalPart > 0) {
      const parsedDecimalString = parseFloat(decimalFormatted).toString();

      if (parsedDecimalString.split(this._decimalSeparator)[1] === undefined) {
        decimalFormatted = '0.' + decimalPart;
      } else {
        decimalFormatted = parsedDecimalString;
      }
    }

    const formattedDecimalPart = decimalFormatted.split(
      this._decimalSeparator,
    )[1];

    // if value is very small, make it 0
    if (
      Math.abs(
        Number(integralPart + this._decimalSeparator + formattedDecimalPart),
      ) <= this._epsilon
    )
      return '0';

    return intFormatted + formattedDecimalPart;
  }

  private countDigits(text: string): number {
    let numberOfDigits = text.length;
    if (text.indexOf(this._decimalSeparator) > -1) {
      numberOfDigits--;
    }
    return numberOfDigits;
  }
}
