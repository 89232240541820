<div
  [class.reduced-font-size]="formula.length > 0"
  class="calculator-wrapper"
  cdkDrag
  cdkDragBoundary="body"
  [cdkDragDisabled]="!canDrag"
  #calculatorWrapper>
  <div
    cdkDragHandle
    class="cursor-all control-row span-all-columns d-flex align-items-center">
    <div class="d-flex gap-2 align-items-center">
      <button
        container="body"
        placement="bottom"
        ngbTooltip="Close Calculator"
        cpDoNotFocus
        class="close-button btn btn-danger px-3 py-2 px-lg-3 py-lg-2"
        title="Close calculator"
        (click)="_calculatorService.hide()">
        &times;
      </button>
      @if (canDrag) {
        <div
          class="h-100 w-100 flex-1"
          placement="right"
          ngbTooltip="Drag To Move">
          <cp-fa-icon
            icon="up-down-left-right"
            [sharp]="true"
            size="large"
            iconStyle="solid" />
        </div>
      }
    </div>
    <!-- Results row -->
    <div class="d-flex justify-content-between w-100">
      <div
        class="flex-end justify-content-end flex-fill formula-history d-flex align-items-center">
        <ng-container *ngFor="let f of formula">
          <ng-container [ngSwitch]="f">
            <ng-container *ngSwitchCase="'*'">&times;</ng-container>
            <ng-container *ngSwitchCase="'/'">&divide;</ng-container>
            <ng-container *ngSwitchCase="'-'">-</ng-container>
            <ng-container *ngSwitchCase="'+'">+</ng-container>
            <ng-container *ngSwitchDefault>{{
              _formatOutputNumber(f)
            }}</ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="hasResult">=</ng-container>
      </div>
    </div>
  </div>
  <div
    cdkDragHandle
    cpSwipe
    (swipeEnd)="_onBackspace()"
    class="results-row span-all-columns">
    {{ hasError ? 'Error' : displayText }}
  </div>
  <!-- 2nd row row -->
  <button
    cpDoNotFocus
    class="clear-button"
    (click)="clear()"
    [cpParHotkey]="[KeyboardKey.Delete, KeyboardKey.KeyC]">
    C
  </button>
  <button cpDoNotFocus class="clear-button" (click)="_onBackspace()">
    <cp-fa-icon iconStyle="solid">delete-left</cp-fa-icon>
  </button>
  <button
    cpDoNotFocus
    class="operation percent-btn"
    (click)="_onPercentage()"
    [cpParHotkey]="{ key: KeyboardKey.Digit5, req: { requireShift: true } }">
    &percnt;
  </button>
  <button
    cpDoNotFocus
    class="operation right-col-operations"
    (click)="_onOperation('/')"
    [cpParHotkey]="[KeyboardKey.NumpadDivide, KeyboardKey.Slash]">
    &divide;
  </button>
  <!-- 3rd row -->
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('7')"
    [cpParHotkey]="[KeyboardKey.Numpad7, KeyboardKey.Digit7]">
    7
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('8')"
    [cpParHotkey]="[KeyboardKey.Numpad8, KeyboardKey.Digit8]">
    8
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('9')"
    [cpParHotkey]="[KeyboardKey.Numpad9, KeyboardKey.Digit9]">
    9
  </button>
  <button
    cpDoNotFocus
    class="operation right-col-operations"
    (click)="_onOperation('*')"
    [cpParHotkey]="[
      KeyboardKey.NumpadMultiply,
      { key: KeyboardKey.Digit8, req: { requireShift: true } },
    ]">
    &times;
  </button>
  <!-- 4rd row -->
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('4')"
    [cpParHotkey]="[KeyboardKey.Numpad4, KeyboardKey.Digit4]">
    4
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('5')"
    [cpParHotkey]="[KeyboardKey.Numpad5, KeyboardKey.Digit5]">
    5
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('6')"
    [cpParHotkey]="[KeyboardKey.Numpad6, KeyboardKey.Digit6]">
    6
  </button>
  <button
    cpDoNotFocus
    class="operation right-col-operations"
    (click)="_onOperation('-')"
    [cpParHotkey]="[KeyboardKey.NumpadSubtract, KeyboardKey.Minus]">
    -
  </button>
  <!-- 5rd row -->
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('1')"
    [cpParHotkey]="[KeyboardKey.Numpad1, KeyboardKey.Digit1]">
    1
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('2')"
    [cpParHotkey]="[KeyboardKey.Numpad2, KeyboardKey.Digit2]">
    2
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('3')"
    [cpParHotkey]="[KeyboardKey.Numpad3, KeyboardKey.Digit3]">
    3
  </button>
  <button
    cpDoNotFocus
    class="operation right-col-operations"
    (click)="_onOperation('+')"
    [cpParHotkey]="[
      KeyboardKey.NumpadAdd,
      { key: KeyboardKey.Equal, req: { requireShift: true } },
    ]">
    +
  </button>
  <!-- 6rd row -->
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onDecimal()"
    [cpParHotkey]="[KeyboardKey.NumpadDecimal, KeyboardKey.Period]">
    {{ _decimalSeparator }}
  </button>
  <button
    cpDoNotFocus
    class="operand"
    (click)="_onNumber('0')"
    [cpParHotkey]="[KeyboardKey.Numpad0, KeyboardKey.Digit0]">
    0
  </button>
  <button
    cpDoNotFocus
    class="equals-button span-2-columns"
    (click)="_onEqual()"
    [cpParHotkey]="[
      KeyboardKey.Enter,
      KeyboardKey.NumpadEnter,
      KeyboardKey.Equal,
    ]">
    =
  </button>
</div>
